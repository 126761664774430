/* global Word,Excel console */

/**
 * function to convert keyable key inyo camel case
 * @param keyableString : string that needs to be converted to camel case
 * @returns camel cased properties
 */
function convertKeyableToCamel(keyableString: string): string {
  // Split the string into words based on hyphen delimiter
  const words = keyableString.split("-");

  // Capitalize the first letter of each word after the first word
  const camelCaseWords = words.map((word, index) =>
    index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together to form camelCase string
  return camelCaseWords.join("");
}

/**
 *
 * @param context current document context
 * @param customProperties custom properties from the file
 * @returns custom
 */
async function getCustomProperties(context, customProperties) {
  customProperties.load("key,type,value");
  await context.sync();
  return customProperties;
}
/**
 * function to get the custom properties from the office file type
 * @param hostType office file host type
 * @returns cqProps custom properties
 */
export async function getCQPropertiesFromOfficeFiles(hostType) {
  try {
    let cqProps = {}; // modify and stores the custom properties for further use
    let customProperties; //holds properties that is stored in the file

    if (hostType === "Word") {
      await Word.run(async (context) => {
        customProperties = await getCustomProperties(context, context.document.properties.customProperties);
      });
    } else if (hostType === "Excel") {
      await Excel.run(async (context) => {
        customProperties = await getCustomProperties(context, context.workbook.properties.custom);
      });
    } else {
      throw new Error("Unsupported host type");
    }
    for (let i = 0; i < customProperties.items.length; i++) {
      if (customProperties.items[i].key.startsWith("cq-")) {
        let key = convertKeyableToCamel(customProperties.items[i].key.substring(3));
        cqProps[key] = customProperties.items[i].value;
      }
    }
    return cqProps;
  } catch (error) {
    console.error("Error fetching custom properties:", error);
    return {};
  }
}

import * as React from "react";
import { useEffect, useState } from "react";
import CqAddin from "./CqAddin";
import { makeStyles } from "@fluentui/react-components";
import { getCqProperties, setAutoOpenOn } from "../taskpane";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App: React.FC = () => {
  const styles = useStyles();

  let [cqProps, setcqProps] = useState({});

  /**
   * function to get cq Properties and auto open the app in the side panel if cq-properties is not empty
   */
  async function getCqProps() {
    setcqProps(await getCqProperties());
    if (Object.keys(cqProps).length !== 0) {
      setAutoOpenOn();
    }
  }

  useEffect(() => {
    getCqProps();
  }, []);

  return (
    <div className={styles.root}>
      <CqAddin cqProperties={cqProps}></CqAddin>
    </div>
  );
};

export default App;

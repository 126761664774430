import { getCQPropertiesFromOfficeFiles } from "./office";

/* global Office */

/**
 * function that returns cq properties for the various office file types
 * @returns promise
 */
export async function getCqProperties(): Promise<any> {
  return new Promise((resolve, reject) => {
    Office.onReady(async (info) => {
      try {
        const properties = getCQPropertiesFromOfficeFiles(info.host);
        resolve(properties);
      } catch (error) {
        reject(error);
      }
    });
  });
}
/**
 * function to auto open cq office addin task pane
 */
export function setAutoOpenOn() {
  Office.context.document.settings.set("Office.AutoShowTaskpaneWithDocument", true);
  Office.context.document.settings.saveAsync();
}
